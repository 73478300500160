<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">{{ currentTable }}</h4>
          <div class="row">
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select class="select-primary pagination-select" v-model="pagination.perPage" placeholder="Por página">
                <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item"></el-option>
              </el-select>
              <div class="d-flex align-items-center">
                <el-button class="btn new-btn mr-3" @click="$router.push({path: '/material'})">Cadastrar Novo</el-button>
                <el-input type="search" prefix-icon="el-icon-search" placeholder="Procurar item" v-model="searchQuery" aria-controls="datatables"></el-input>
              </div>
            </div>
            <div class="col-12">
              <el-table :data="queriedData.filter(data => !searchQuery || data.name.toLowerCase().includes(searchQuery.toLowerCase()))" v-if="total > 0">
                <el-table-column v-slot="props" :min-width="20" v-if="total > 0" align="left" label="Imagem">
                  <div class="photo material-cover">
                    <img :src="props.row.photo_url" alt="" />
                  </div>
                </el-table-column>

                <el-table-column v-for="column in tableColumns" :min-width="column.minWidth" :key="column.label" :prop="column.prop" :label="column.label"></el-table-column>

                <el-table-column align="center" label="Status">
                  <template v-slot="props">
                    <span>{{props.row.status == 1 ? 'Ativo':'Inativo'}}</span>
                  </template>
                </el-table-column>

                <el-table-column width="108" v-if="total > 0" align="center" label="Ações">
                  <template v-slot="props">
                    <el-button type="warning" icon="tim-icons icon-pencil" plain circle @click="edit(props.$index, props.row)" size="small"></el-button>
                    <el-button v-if="props.row.status === 1" type="success" icon="tim-icons icon-check-2" plain circle @click="handleDelete(props.$index, props.row)" size="small"></el-button>
                    <el-button v-else type="danger" icon="tim-icons icon-simple-remove" plain circle @click="handleActivate(props.$index, props.row)" size="small"></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div slot="footer" class="col-12 mt-3 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div>
                <p class="card-category table-footer" v-if="total > 0">
                  Exibindo de {{ from + 1 }} à {{ to }} de {{ total }} registros
                </p>
                <p class="card-category table-footer" v-if="total === 0">
                  Nenhum registro encontrado.
                </p>
              </div>
              <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="total" v-if="total > 0"></base-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { BasePagination } from '@/components'
import Fuse from 'fuse.js'
import swal from 'sweetalert2'

export default {
  data () {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100],
        total: 0
      },
      searchQuery: '',
      propsToSearch: [],
      tableColumns: [],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    }
  },
  methods: {
    edit (index, row) {
      return this.$router.push(`/material/${row.id}`)
    },
    handleActivate (index, row) {
      swal.fire({
        title: 'Você tem certeza?',
        text: `Você está prestes a ativar "${row.name}".`,
        showCancelButton: true,
        confirmButtonText: 'Sim, eu quero ativar!',
        cancelButtonText: 'Cancelar'
      }).then(result => {
        if (result.value) {
          swal.fire({
            title: 'Ativando',
            text: `Aguarde, estamos ativando "${row.name}".`,
            onBeforeOpen: () => {
              swal.showLoading()
            }
          })
          this.deleteRow(row).then(status => {
            if (!status) {
              this.getData()
              swal.fire({
                title: 'Ativado!',
                text: `Você ativou "${row.name}"`,
                showConfirmButton: false,
                timer: 2000
              })
            } else {
              swal.fire({
                title: 'Error!',
                text: `Ocorreu um erro ao ativar "${row.name}"`,
                confirmButtonText: 'Ok',
                showConfirmButton: true
              })
            }
          })
        }
      })
    },
    handleDelete (index, row) {
      swal.fire({
        title: 'Você tem certeza?',
        text: `Você está prestes a inativar "${row.name}".`,
        showCancelButton: true,
        confirmButtonText: 'Sim, eu quero inativar!',
        cancelButtonText: 'Cancelar'
      }).then(result => {
        if (result.value) {
          swal.fire({
            title: 'Inativando',
            text: `Aguarde, estamos inativando "${row.name}".`,
            onBeforeOpen: () => {
              swal.showLoading()
            }
          })
          this.deleteRow(row).then(status => {
            if (!status) {
              this.getData()
              swal.fire({
                title: 'Deletado!',
                text: `Você deletou "${row.name}"`,
                showConfirmButton: false,
                timer: 2000
              })
            } else {
              swal.fire({
                title: 'Error!',
                text: `Ocorreu um erro ao deletar "${row.name}"`,
                denyButtonText: 'Ok',
                showDenyButton: true,
                showConfirmButton: false
              })
            }
          })
        }
      })
    },
    async deleteRow (row) {
      let status
      try {
        status = (await this.$http.delete(`items/${row.id}/${row.status}`)).data.status
      } catch (e) {
        status = e.response.status
      }
      if (status === 200) {
        const indexToDelete = this.tableData.findIndex(
          tableRow => tableRow.id === row.id
        )
        if (indexToDelete >= 0) {
          this.tableData[indexToDelete].status = 'Inativo'
        }
        return true
      }
      return false
    },
    async getData () {
      const url = 'items'
      const { data } = await this.$http.get(url)
      this.tableData = data.data
      this.tableColumns = [
        {
          prop: 'id',
          label: 'Id',
          minWidth: 20
        },
        {
          prop: 'name',
          label: 'Nome'
        },
        {
          prop: 'title',
          label: 'Categoria'
        }
      ]
      this.propsToSearch = ['title', 'type']
    }
  },
  async mounted () {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['name', 'email'],
      threshold: 0.3
    })
    await this.getData()
  },
  watch: {
    searchQuery (value) {
      let result = this.tableData
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery)
      }
      this.searchedData = result
    }
  },
  components: {
    BasePagination
  },
  computed: {
    queriedData () {
      let result = this.tableData
      if (this.searchedData.length > 0) {
        result = this.searchedData
      }
      return result.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length
    },
    currentTable () {
      const msg = `lista de ${this.$route.name}`
      return msg.toUpperCase()
    }
  }
}
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}

p.table-footer {
  text-transform: none;
}

.material-cover {
  position: relative;
  height: 45px !important;
  width: 45px !important;
  margin-left: 0 !important;
  background: #eee;
  border: 1px solid #ccc;
}

.material-cover img {
  position: absolute;
  max-height: 100%;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn.new-btn{
  overflow: visible !important;
}
</style>
